.ground-floor-carousel-container {
  top:0%;
  width: 100vw;
  height: 100vh;
}

.ground-floor-carousel-image-container {
  height: 100vh;
  width: 100vw;
}

.ground-floor-carousel-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}