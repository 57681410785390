.ground-floor-safety-video {
  position:fixed;
  height:100%;
  width:100%;
  background-color: #000000;
  color: #FFFFFF;
  overflow-y: none;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
  
  .ground-floor-safety-video-stop-button {
    font-weight: normal;
    position:fixed;
    top:1vh;
    left:1vw;
    width:8vw;
    height: 5vh;
    font-size:1.2vw;
    background-color: #A00000;
    color: #ffffff;
    text-align: center;
    opacity:100%;
    display:flex;
    align-items: center;
    justify-content: center;
  }