.dw-home-panel-telemetry-wrapper 
{
    display:flex;
    flex-direction: row;
    align-items: left;
    justify-content:flex-start;
    padding: 0.6vw;
    height: 100%;
}

.dw-home-panel-telemetry-group
{
    display:flex;
    flex-direction: row;
    align-items: left;
    width:100%;
    height:100%
}

.dw-home-panel-telemetry-group-time
{
    display:flex;
    flex-direction: column;
    align-items: left;
    justify-content:center;
    width:15vw;
    height:10vh;
}


.dw-home-panel-telemetry-group-weather-station
{
    display:flex;
    flex-direction: column;
    align-items: left;
    width:27vw;
    justify-content: left;
}

.dw-home-panel-telemetry-group-cap-monitor
{
    display:flex;
    flex-direction: column;
    align-items: left;
    width:42vw;
    justify-content: left;
}


.dw-home-panel-telemetry-group-heading
{
    font-family: "Roboto";
    font-size:1vw;
    text-align: left;
    font-weight: 500;
    display: flex;
    flex-direction: row;
}

.dw-home-panel-telemetry-live
{
    background-color:#FF0000;
    padding-left: 0.4vw;
    padding-right: 0.4vw;
    margin-right: 0.4vw;
    font-weight:bold;
    align-items: center;
    display:flex;
}

.dw-home-panel-telemetry-group-data
{
    display:flex;
    flex-direction: row;
    align-items: left;
    justify-content:left;
    padding-top:0.6vh;
}

.dw-telemetry-temp
{
    width: 9vw;
}

.dw-telemetry-wind
{
    width: 5vw;
}

.dw-telemetry-wind-arrow
{
    padding-top:1vh;
    width: 2vw;
}

.dw-telemetry-cap-facing
{
    width: 9vw;
}

.dw-telemetry-sail-speed
{
    width: 11vw;
}

.dw-telemetry-fantail-speed
{
    width: 11w;
}

.dw-telemetry-item-heading
{
    text-align:left;
    font-size: 0.9vw;
}

.dw-telemetry-item-main-row
{
    display:flex;
    flex-direction: row;
    align-items: right;
}

.dw-telemetry-item-main
{
    display:flex;
    flex-direction: row;
    align-items: baseline;
}

.dw-telemetry-item-main-row-item-left
{
    margin-right:0.4vw;
    padding:0.1vw;
}

.dw-telemetry-item-headline-stat
{
    font-family: "Roboto";
    font-weight: 500;
    font-size: 2vw;
    text-align: left;
}

.dw-telemetry-item-unit
{
    font-size: 0.8vw;
}

.dw-telemetry-item-lower
{
    padding-top: 0vh;
    text-align: left;
    font-size: 0.8vw;
    line-height: 1.6vh;
}