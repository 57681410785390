
.home-main-panel {
    position:fixed;
    top:0%;
    left:29%;
    width:71%;
    height:100%;
    font-size:1.6vw;
    text-align: center;
    justify-items: center;
  }
  
.dw-home-panel-item {
    width: 94%;
    height: 94%;
    text-align: left;
    padding: 0.6vw;
}

.dw-home-panel-item-heading {
    font-family: "Roboto";
    text-transform: uppercase;
    font-weight: 200;
    font-size: 1.4vw;
    line-height: 110%;
}

.dw-home-panel-item-content {
  position:absolute;
  top:4vh;
  width:19.8vw;
  height:25vh;
  margin-top: 0.8vh;
  margin-bottom: 0.8vh;
}

.dw-home-panel-item-content-img {
  object-fit: cover;
}

.dw-home-panel-item-content-cover {
  position:absolute;
  top:0vh;
  height:25vh;
  width:19.8vw;
  opacity: 0%;
  background-color: black;
  z-index: 100;
}

.dw-home-panel-item-text {
    position:absolute;
    top:30.5vh;
    font-size:var(--font-size-standard-text);
    width:19.8vw;
  }


  .home-panel-1 {
    position:fixed;
    top:2%;
    left:31%;
    width:21%;
    height: 38%;
    background-color: var(--highlight-colour-1);
  }
  
  .home-panel-2 {
    position:fixed;
    top:2%;
    left:54.25%;
    width:21%;
    height: 38%;
    background-color: var(--highlight-colour-1);
  }
  
  .home-panel-3 {
    position:fixed;
    top:2%;
    left:77.5%;
    width:21%;
    height:38%;
    background-color: var(--highlight-colour-1);
  }
  
  .home-panel-4 {
    position:fixed;
    top:43%;
    left:31%;
    width:21%;
    height:38%;
    background-color: var(--highlight-colour-1);
  }
  
  .home-panel-5 {
    position:fixed;
    top:43%;
    left:54.25%;
    width:21%;
    height: 38%;
    background-color: var(--highlight-colour-1);
  }

  .home-panel-5-overlay {
    position:fixed;
    top:63.5%;
    left:55.5%;
    width:17%;
    height: 38%;
    text-align: left;
    font-family: "Roboto";
    font-weight: 100;
    line-height: 120%;
  }

  .home-panel-5-overlay-row-upper
{
    font-family: "Roboto";
    font-size:1vw;
    text-align: left;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    margin-bottom: 0vw;
    align-items: baseline;
}

.home-panel-5-overlay-row-lower
{
    font-family: "Roboto";
    font-size:1vw;
    text-align: left;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.3vw;
    align-items: center;
}


.home-panel-5-overlay-live
{
    background-color:#FF0000;
    line-height: 120%;
    padding-left: 0.4vw;
    padding-right: 0.4vw;
    margin-right: 0.4vw;
    font-weight:bold;
    align-items: center;
    display:flex;
}

.home-panel-5-overlay-stat
{
    display:flex;
    flex-direction: row;
    align-items: left;
    justify-content:left;
    padding-top:0.6vh;
    font-size:3vw;
    margin-right: 0.6vw;
}

  .home-panel-6 {
    position:fixed;
    top:43%;
    left:77.5%;
    width:21%;
    height:38%;
    background-color: var(--highlight-colour-1);
  }
  
  .home-panel-telemetry {
    position:fixed;
    top:84%;
    left:31%;
    width:67.5%;
    height: 14%;
    background-color: var(--highlight-colour-3);
  }

  .home-panel-juniors {
    position:fixed;
    top:85%;
    left:77%;
    width:21%;
    height: 12%;
    background-color: var(--highlight-colour-2);
  }
  
  