.dw-swiper-container {
  width:69vw;
  padding-left:1vw;
  padding-right:1vw;
  padding-top:1.5vh;
  padding-bottom:1.5vh;
  height:100%;
}

/* NOTE - on production service, these swiper overrides do not take effect without !important */
/* on dev service they work ok without it */

.swiper-pagination-bullet {
  background-color: #606060 !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background-color: #FFFFFF !important;
}

.swiper-button-next {
  top:92% !important;
  width:4% !important;
  height:6% !important;
  --swiper-navigation-color: #FFFFFF;
  background-image: url(rightarrow.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  top:92% !important;
  width:4% !important;
  height:6% !important;
  --swiper-navigation-color: #FFFFFF;
  background-image: url(leftarrow.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.swiper-button-prev::after {
  display: none;
}

.dw-swiper-content {
  display:flex;
  flex-direction: column;
}

.dw-swiper-item-caption {
  height:11vh;
  font-size: 0.9vw;
  padding-left:7vw;
  padding-right:7vw;
  padding-bottom: 2vh;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
}

.dw-swiper-item-image-container {
  height:86vh;
  width:100%;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
}

.dw-swiper-item-image-container img {
  max-width:100%;
  max-height:100%;
  margin:auto;
  display:block;
}