.ground-floor-background {
  position:fixed;
  height:100%;
  width:100%;
  background-color: #000000;
  color: #FFFFFF;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
  
  .ground-floor-main-panel {
    position:fixed;
    top:0%;
    left:0%;
    width:100%;
    height: 90%;
    font-size:2vw;
    background-color: #FFFFFF;
    color: #000000;
    text-align: center;
  }

  .ground-floor-lower-panel {
    position:fixed;
    top:90%;
    left:0%;
    width:100%;
    height: 10%;
    font-size:2vw;
    background-color: #FFFFFF;
    color: #000000;
    text-align: center;
  }



  .ground-floor-button-panel {
    position:fixed;
    font-weight: bold;
    top:67%;
    left:1.5%;
    width:12vw;
    height: 30%;
    font-size:1.3vw;
    background-color: #f0f0f0;
    opacity: 92%;
    color: #000000;
    text-align: center;
    border-color:#ffffff;
    border-width: 16px;
    padding-top: 1vh;
    padding-left: 1vw;
    padding-right: 1vw;
  }

  .ground-floor-button-panel-title {
    display:flex;
    flex-direction: row;
    text-align: left;
  }

  .ground-floor-welcome-text {
    margin-left:0.5vw;
    font-size: 1.3vw;
    font-weight: normal;
    line-height: 1.6vw;
  }

  .ground-floor-safety-video-button {
    font-weight: normal;
    position:fixed;
    top:79%;
    left:2.5;
    width:12vw;
    height: 5%;
    font-size:1.2vw;
    background-color: #00A000;
    color: #ffffff;
    text-align: center;
    opacity:100%;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .ground-floor-live-cameras-button {
    font-weight: normal;
    position:fixed;
    top:85%;
    left:2.5vw;
    width:12vw;
    height: 5%;
    font-size:1.2vw;
    background-color: #202020;
    color: #ffffff;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .ground-floor-live-cameras-submit-info {
    position:fixed;
    top:91%;
    left:2.5w;
    width:12vw;
    font-weight: normal;
    font-size: 0.7vw;
  }
