
.smartmolen-map {
  width: var(--iframe-zoom);
  height: var(--iframe-zoom);
  transform:scale(var(--iframe-scale));
  transform-origin: 0% 0% 0px;
}

.smartmolen-content {
  width: 100%;
  height: 100%;
}

.windy-link-block {
  position: fixed;
  top: 1vh;
  left:60.5vw;
  width:8vw;
  height:5vh;
  background-color: black;
  opacity: 0%;
  z-index: 100;
}


.windy-menu-block {
  position: fixed;
  top: 0.5vh;
  left:96.5vw;
  width:3.7vw;
  height:2.5vh;
  background-color: black;
  opacity: 0%;
  z-index: 100;
}


.console-live-panel {
  position: fixed;
  left: 74%;
  top: 79.5%;
  width: 24%;
  height: 11.5%;
  background-color: #c00000;
  padding: 1vh;
  font-size: 2.4vh;
  font-family: "Open Sans";
  text-align: left;
}

.console-live-panel-top {
  display: flex;
  flex-direction: row;
  align-items:center

}

.console-live-panel-clock {
  font-size: 2vh;
}

.console-live-panel-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.4vh;
  margin-top: 1vh;
}

.console-live-panel-qr-code {
  position: fixed;
  left: 92%;
  top: 80.5%;
  width: 10vh;
  height: 10vh;
  background-color: #FFFFFF;
  justify-content: center;
  align-items: center;
  display: flex;
}

.console-live-title {
  font-size: 2.4vh;
  font-family: "Open Sans";
  margin-left: 1vh;
  margin-right: 1vh;
}
