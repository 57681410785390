.dw-home-left-panel-title {
  font-family: "Roboto";
  font-weight: 200;
  font-size: 3.6vw;
  line-height: 105%;
}

.dw-home-left-touch-screen-instruction {
  background-color: var(--highlight-colour-2);
  color: var(--highlight-colour-2-text);
  padding: 0.8vw;
  display: flex;;
  flex-direction: row;
  align-items: center;
  font-size: 1.2vw;
}

.dw-home-left-touch-return-to-home-screen-instruction {
  background-color: var(--highlight-colour-1);
  color: var(--highlight-colour-1-text);
  padding: 0.8vw;
  display: flex;;
  flex-direction: row;
  align-items: center;
  font-size: 1.2vw;
}

.dw-home-left-touch-screen-icon {
  animation: blinker 1.5s linear infinite;
  padding-right:0.6vw;
}

@keyframes blinker {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
