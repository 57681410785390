.dw-wind-energy-left-panel-wind-info {
    background-color: #E8E8E8;
    padding:11px;
}

.dw-wind-energy-left-panel-heading {
    font-weight: bold;
    font-size: 18px;
}

.dw-wind-energy-left-panel-stat-row {
    display:flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.dw-wind-energy-left-panel-stat-box {
    background-color: #176397;
    color: #FFFFFF;
    padding:6px;
    width: 32%;
    margin-right: 2%;
    text-align: right;
}

.dw-wind-energy-left-panel-stat {
    font-family: "Roboto Condensed";
    font-weight: bold;
    font-size: 26px;
}

.dw-wind-energy-left-panel-balancing-info {
    background-color: #E8E8E8;
    padding:12px;
    margin-top:12px;
}