.dw-pastin3d-entry {
    display:flex;
    padding-bottom:0.5vh;
    padding-top:0.5vh;
    flex-direction: column;
    padding-left: 1vw;
    padding-right: 1vw;
}

  .dw-pastin3d-entry-name {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-family: "Open Sans";
    font-size:1.2vw;
    padding-left:1vw;
    font-weight:normal;
  }

  .dw-pastin3d-category-heading {
    font-family: "Open Sans";
    font-size:1.2vw;
    font-weight:bold;
    text-align: left;
    padding-left:1vw;
    padding-top:0.8vh;
    padding-bottom:0.6vh;
    background-color:#46402b;
  }