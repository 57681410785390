.dw-timeline-entry {
    display:flex;
    padding-bottom:2vh;
    padding-top:2vh;
    flex-direction: column;
    padding-left: 1vw;
    padding-right: 1vw;
    background: #303030;
    color: #FFFFFF;
}

  .dw-timeline-entry-header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .dw-timeline-entry-date {
    font-family: "Roboto Condensed";
    font-size:1.3vw;
    line-height: 110%;
    font-weight: bold;
  }
  
  .dw-timeline-entry-headline {
    font-size: 1.1vw;
    line-height: 110%;
    font-family: "Roboto Condensed";
  }
  
  .dw-timeline-entry-text {
    padding-top:0.2vh;
    padding-left:3.25vw;
    font-family: "Open Sans";
    font-weight:400;
    font-size:var(--font-size-standard-text);
  }