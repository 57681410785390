.past-in-3d-model {
  width: 100%;
  height: 85%;
}
.past-in-3d-model-caption {
  height: 15%;
  font-size: 0.8vw;
  padding-left:2vw;
  padding-right:2vw;
  padding-bottom: 1vh;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
}