.console-table-item-container {
    font-family: "Open Sans";
    display:flex;
    flex-direction: row;
    align-items:center;
    width:26.7vw;
    padding:0.4vh;
    background-color: #E0E0E0;
    margin-bottom: 0.4vh;
    font-size: 0.65vw;
}

.console-table-item-col1 {
  width: 11vw;
}

.console-table-item-title {
  font-family: 'Raleway';
  font-weight: bold;
  font-size: 1vw;
}

.console-table-item-subtitle {
  font-family: 'Open Sans';
  font-size: 0.6vw;
}

.console-table-item-subtitle-red {
  font-family: 'Open Sans';
  color: #F00000;
  font-size: 0.6vw;
}

.console-headline-stat {
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 0.9vw;
}

.console-table-sensor-icons {
  display: flex;
  flex-direction: row;
  padding-top:0.2vh;
}

.console-table-sensor-icon {
  padding-right: 2px;
}


.console-table-item-col2 {
  width: 8vw;  
}

.console-table-item-col3 {
  width: 8vw;
}

.console-table-wind {
  display:flex;
  flex-direction: row;
  align-items:center;
}

.console-table-wind-left {
  width: 5vw;
}

.molen-list-item-webcam-tag {
  color:white;
  background-color: #1A8BE8;
  padding-left: 0.2vw;
  padding-right: 0.2vw;
  margin-right: 0.2vw;
  font-size: 1vh;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  height: 1.5vh;
}