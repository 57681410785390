.home-background {
  position:fixed;
  height:100%;
  width:100%;
  background-color: var(--main-panel-background);
  color: var(--main-panel-text);
}
 
.home-left-panel {
  position:fixed;
  top:0%;
  left:0%;
  width:29%;
  height: 100%;
  font-size:1.4vw;
  background-color: var(--left-panel-background);
  color: var(--left-panel-text);
  text-align: center;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap:6%;
}

.dw-left-panel-header {
  position:fixed;
  top:0%;
  left:0%;
  width:29%;
  height: 12%;
  background-color: var(--highlight-colour-2);
  color: #FFFFFF;
  text-align: center;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  row-gap:6%;
}

.dw-left-panel-header-right {
  font-family: "Roboto";
  font-size:1.6vw;
  display:flex;
  flex-direction: column;
  align-items: left;
  text-align: left;;
  padding-left: 1vw;
  line-height: 110%;
}


.dw-left-panel-content {
  position:fixed;
  top:12%;
  left:0%;
  width:29%;
  height: 78%;
  font-size:var(--font-size-standard-text);
  background-color: var(--left-panel-background);
  color: var(--left-panel-text);
  text-align: left;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.dw-left-panel-content-dark {
  position:fixed;
  top:12%;
  left:0%;
  width:29%;
  height: 78%;
  font-size:var(--font-size-standard-text);
  background-color: var(--left-panel-background-dark);
  color: var(--left-panel-text-dark);
  text-align: left;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}


.dw-left-panel-text {
  padding:0.8vw;
  color: var(--left-panel-text);
  text-align: left;
  font-family: 'Open Sans';
  font-size:var(--font-size-standard-text);
}

.dw-left-panel-text-dark {
  padding:0.8vw;
  color: var(--left-panel-text-dark);
  text-align: left;
  font-family: 'Open Sans';
  font-size:var(--font-size-standard-text);
}

.dw-left-panel-instruction {
  background-color: var(--highlight-colour-3);
  color: var(--highlight-colour-3-text);
  padding:0.8vw;
  text-align: left;
  font-family: 'Open Sans';
  font-size:var(--font-size-standard-text);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dw-left-panel-instruction-text {
  text-align: left;
  font-family: 'Open Sans';
  /*font-size:var(--font-size-standard-text);*/
  font-size:0.9vw;
  padding-left: 0.8vw;
}

.dw-left-panel-sub-content {
  padding:0.8vw;
}


.dw-left-panel-qr-code-right {
  margin-left:0.8vw;
  color: #000000;
  text-align: left;
  font-family: 'Open Sans';
  font-size:0.9vw;
}


.dw-left-panel-content::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.dw-left-panel-footer {
  position:fixed;
  top:90%;
  left:0%;
  width:29%;
  height:10%;
  font-family: "Roboto";
  font-size:1.4vw;
  background-color: var(--highlight-colour-2);
  color: var(--highlight-colour-2-text);
  text-align: center;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap:6%;
}
